<template>
  <div class="grid grid-cols-2">
    <div>
      <div class="flex self-start items-center justify-between h-16 px-8 pt-7">
        <img
          src="/img/portoll-logo.svg"
          alt="Portoll Logo"
          class="w-24"
          draggable="false"
        >

        <header>
          <slot name="headerAction" />
        </header>
      </div>

      <div class="flex items-center h-full max-w-sm mx-auto -mt-16">
        <div class="w-full">
          <slot />
        </div>
      </div>
    </div>

    <div>
      <img
        src="/img/portoll-onboarding-1.png"
        alt="Portoll Onboarding Image"
        class="h-screen object-cover select-none w-full"
        draggable="false"
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
  import {useAuthStore} from '~/stores/useAuthStore';

  const auth = useAuthStore();
</script>
